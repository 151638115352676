@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&family=Source+Sans+Pro:wght@400;600;700&display=swap');

html,
body,
#root,
.app {
  height: 100%;
  width: 100%;
  font-family: 'Noto Sans Arabic', sans-serif;
}

.marker-container {
  object-fit: contain;
}

.custom-marker {
  background-color: #77ffcb;
  color: rgb(0, 0, 0);
  font-style: bold;
  font-size: 16px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 18%;
}
.custom-marker-container1 {
  width: 45px;
  height: 35px;
  padding: 0px;
  background-color: #fff;
  border: 2px solid #ffffff;
  border-radius: 20%;
  cursor: pointer;
}

.custom-marker-container0 {
  width: 45px;
  height: 35px;
  padding: 0px;
  background-color: #fff;
  border: 2px solid #ff3333;
  border-radius: 20%;
  cursor: pointer;
}

/* img{
  height: 500px;
  width: 700px;
  object-fit: cover;
  padding-top:30px;
} */