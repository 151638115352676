@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
*{
    box-sizing: border-box;
}

@media screen and (max-width: 1480px) {
    html{
      font-size: 85%;
    }
  }
  
  @media screen and (max-width: 1280px) {
    html{
      font-size: 80%;
    }
  }
  
  @media screen and (max-width: 1024px) {
    html{
      font-size: 70%;
    }
  }
  
  @media screen and (max-width: 768px) {
    html{
      font-size: 60%;
    }
  }

  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
    padding: 9px 14px !important;
  }
  

.side-nav .active, .side-nav .active:hover{
    color: #CA8A04;
    background-color: #ca8b041a;
}

.oswald {
    font-family: 'Oswald', sans-serif;
}

.anek {
    font-family: 'Anek Gurmukhi', sans-serif;
}

.flat {
    box-shadow: none !important;
    outline: none;
}

.linkPreview:hover {
    background-color: '#181c28';
}

.scrolling {
    overflow-y: auto;
}


/* width */

.scrolling::-webkit-scrollbar {
    width: 2px;
    height: 5px;
}


/* Track */

.scrolling::-webkit-scrollbar-track {
    border-radius: 10px;
    cursor: move;
}


/* Handle */

.scrolling::-webkit-scrollbar-thumb {
    background: #88aacd;
    border-radius: 10px;
}