.nextui-c-jklhtj-ikcISyq-css{
    justify-content: flex-start !important;
}

.nextui-c-fyNvBR{
    height: -webkit-fill-available;
    justify-content: flex-end !important;
}

.nextui-c-egxOLa{
    max-width: 100vw !important;
}

.nextui-navbar-container{
    background-color: #F7F7F7 !important;
    height: 10vh !important;
}